import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { WEB_SERVICES_URL } from '../../constants/Url';
import { useAlertContext } from "../../context/alertContext";
import { ServiciosDisponibles } from '../../constants/ServiciosDisponibles';
import MostrarStars from '../../forms/MostrarStars';
import CancelarServicio from "../../shared/CancelarServicio";

const ServicioEnProceso = ({
  id,
  tipoServicio,
  metrosCuadrados,
  habitaciones,
  banos,
  equiposSuministros,
  fechaHorario,
  presupuestoCliente,
  serviciosAdicionales,
  latitud,
  longitud,
  usuarioSolicitante,
  nombreCliente,
  calificacionCliente
}) => {
  const navigate = useNavigate();
  const { onOpen } = useAlertContext();

  // Encontrar el servicio de limpieza
  const imagen_servicio = ServiciosDisponibles.find(
    (servicio) => servicio.name === tipoServicio
  );

  // Obtener la ruta de la imagen
  const imagen_select = imagen_servicio ? imagen_servicio.src : "https://placehold.co/600x400";
  
  const [clicked, setClicked] = useState(false);
  const handleClickFinalizarServicio = () => {
    const token = localStorage.getItem('id_token');

    axios.put(WEB_SERVICES_URL+`/limpieza/finalizar/${id}`, { token: token })
      .then(response => {
        // Manejar la respuesta según sea necesario
        // console.log(response.data);
        setClicked(true);
        onOpen("success", "El servicio se finalizó correctamente.");
        navigate("/finalizados");
    })
      .catch(error => {
        // Manejar errores de la solicitud
        onOpen("error", "No se puede finalizar antes de la fecha programada.");
        console.error('Error al finalizar el servicio:', error);
      });
  };

  return (
    !clicked && <div className="w3-third w3-container w3-margin-bottom card-servicios">
      
      <div className="flex align-items-center">
        <CancelarServicio message={"¿Estás seguro de cancelar el servicio?"} id={id} mode_={'proveedor'}/>
        <picture className="image-container">
          <img
            src={imagen_select}
            alt={tipoServicio}
            className="responsive-image w3-hover-opacity"
          />
        </picture>
        <div className="flex flex-col align-items-center">
          <h2 className="service-title">
            <span className="icon-blue">📦</span> {tipoServicio}
          </h2>
          <div className="p-10">
            <h4 className=""><b>{nombreCliente}</b></h4>
            { usuarioSolicitante !== "" ? <MostrarStars rating={calificacionCliente || 5} totalStars={5}/> : <></> }
          </div>
        </div>
      </div>

      <div className="service-info">
        <p><span className="icon-blue">🏠</span> <b>Metros Cuadrados:</b> {metrosCuadrados} m²</p>
        <p><span className="icon-blue">🛏</span> <b>Habitaciones:</b> {habitaciones}</p>
        <p><span className="icon-blue">🚿</span> <b>Baños:</b> {banos}</p>
        <p>
          {equiposSuministros ? <span className="icon-green">✔️</span> : <span className="icon-red">❌</span>}
          <b> Tengo Equipos y Suministros:</b> {equiposSuministros ? "Sí" : "No"}
        </p>

        <p><span className="icon-blue">📅</span> <b>Fecha y Horario:</b> {fechaHorario}</p>
        <p><span className="icon-blue">📋</span> <b>Servicios Adicionales:</b> {serviciosAdicionales}</p>
        <p><span className="icon-green">💲</span> <b>Presupuesto del Cliente:</b> BOB {presupuestoCliente}</p>
      </div>

      {latitud && longitud && (
        <p className="map-link">
          <a
            href={`https://www.google.com/maps?q=${latitud},${longitud}`}
            target="_blank"
            rel="noopener noreferrer"
            className="service-button"
          >
            📍 Ver ubicación en el mapa
          </a>
        </p>
  )}

      <div className="footer-servicio">
        <button className="btn btn-custom" onClick={handleClickFinalizarServicio}>Marcar finalizado</button>
      </div>
    </div>
  );
};

export default ServicioEnProceso;
