import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const Leftside = (props) => {
  const { isLoggedIn, mode } = useAuth();
  const location = useLocation();

  function closeSidebar() {
    props.setIsSidebarOpen(false);
  }

  return (
    <>
      <nav className={`w3-sidebar ${!props.isSidebarOpen ? 'w3-collapse' : ''} w3-white w3-animate-left`} style={{ width: 300, zIndex: 3 }} id="mySidebar"><br />
        <div className="w3-container">
          <a href="#" onClick={closeSidebar} className="w3-hide-large w3-right w3-jumbo w3-padding w3-hover-grey" title="close menu">
            <i className="fa fa-remove"></i>
          </a>
          <h4 className='text-primary'><b>Un Click</b></h4>
        </div>
        <div className="w3-bar-block">
          <Link onClick={closeSidebar} className={`hide-small w3-bar-item w3-button w3-padding text-primary ${location.pathname === '/' ? 'active-menu' : ''}`} to="/"><i className="fa fa-home fa-fw w3-margin-right"></i> Inicio</Link>
          {isLoggedIn() && mode === 'cliente' && <Link onClick={closeSidebar} className={`hide-small w3-bar-item w3-button w3-padding text-primary ${location.pathname === '/solicitados' ? 'active-menu' : ''}`} to="/solicitados"><i className="fa fa-home fa-fw w3-margin-right"></i> Servicios Solicitados </Link>}
          {isLoggedIn() && <Link onClick={closeSidebar} className={`hide-small w3-bar-item w3-button w3-padding text-primary ${location.pathname === '/pendientes' ? 'active-menu' : ''}`} to="/pendientes"><i className="fa fa-home fa-fw w3-margin-right"></i> {mode === 'cliente' ? "Servicios En Curso" : "Mis Pendientes"}</Link>}
          {isLoggedIn() && <Link onClick={closeSidebar} className={`w3-bar-item w3-button w3-padding text-primary ${location.pathname === '/finalizados' ? 'active-menu' : ''}`} to="/finalizados"><i className="fa fa-home fa-fw w3-margin-right"></i> Historial de servicios</Link>}
          {!isLoggedIn() && <Link onClick={closeSidebar} className={`w3-bar-item w3-button w3-padding text-primary ${location.pathname === '/login' ? 'active-menu' : ''}`} to="/login"><i className="fa fa-user fa-fw w3-margin-right"></i> Login</Link>}
          <Link onClick={closeSidebar} className={`w3-bar-item w3-button w3-padding text-primary ${location.pathname === '/about' ? 'active-menu' : ''}`} to="/about"><i className="fa fa-user fa-fw w3-margin-right"></i>Acerca de</Link>
          {isLoggedIn() && <Link onClick={closeSidebar} className={`w3-bar-item w3-button w3-padding text-primary ${location.pathname === '/logout' ? 'active-menu' : ''}`} to="/logout"><i className="fa fa-sign-out fa-fw w3-margin-right"></i> Logout</Link>}
        </div>
        <div className="w3-panel w3-large social-icons">
          <i className="text-primary fa fa-facebook-official w3-hover-opacity"></i>
          <i className="text-primary fa fa-instagram w3-hover-opacity"></i>
          <i className="text-primary fa fa-snapchat w3-hover-opacity"></i>
          <i className="text-primary fa fa-pinterest-p w3-hover-opacity"></i>
          <i className="text-primary fa fa-twitter w3-hover-opacity"></i>
          <i className="text-primary fa fa-linkedin w3-hover-opacity"></i>
        </div>
      </nav>
      <div className="w3-overlay w3-hide-large w3-animate-opacity" onClick={closeSidebar} title="close side menu" id="myOverlay"></div>
    </>
  );
};

export default Leftside;
