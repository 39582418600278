import React, {useState} from "react";
import axios from "axios";
import { WEB_SERVICES_URL } from '../../constants/Url';
import InputStars from '../../forms/InputStars';
import { ServiciosDisponibles } from '../../constants/ServiciosDisponibles';
import MostrarStars from '../../forms/MostrarStars';

const ClienteServicioFinalizado = ({
  id,
  tipoServicio,
  metrosCuadrados,
  habitaciones,
  banos,
  equiposSuministros,
  fechaHorario,
  presupuestoCliente,
  serviciosAdicionales,
  usuarioSolicitante,
  nombreCliente,
  calificacionCliente
}) => {
  // Encontrar el servicio de limpieza
  const imagen_servicio = ServiciosDisponibles.find(
    (servicio) => servicio.name === tipoServicio
  );

  // Obtener la ruta de la imagen
  const imagen_select = imagen_servicio ? imagen_servicio.src : "https://placehold.co/600x400";
  
  const [userRating, setUserRating] = useState(0);

  const handleRatingChange = (rating) => {
    setUserRating(rating);
  };
  
  const [clicked, setClicked] = useState(false);
  const handleClickCalificarCliente = () => {
    const token = localStorage.getItem('id_token');

    axios.put(WEB_SERVICES_URL+`/limpieza/calificarcliente/${id}`, { calificacionCliente: userRating, token: token })
      .then(response => {
        // Manejar la respuesta según sea necesario
        // console.log(response.data);
        setClicked(true);
    })
      .catch(error => {
        // Manejar errores de la solicitud
        console.error('Error al aceptar el servicio:', error);
      });
  };

  return (
    <div className="w3-third w3-container w3-margin-bottom card-servicios">
      <div className="flex align-items-center">
        <picture className="image-container">
          <img
            src={imagen_select}
            alt={tipoServicio}
            className="responsive-image w3-hover-opacity"
          />
        </picture>
        <div className="flex flex-col align-items-center p-10">
          <h2 className="service-title">
            <span className="icon-blue">📦</span> {tipoServicio}
          </h2>
          <div className="p-10">
            <h4 className=""><b>{nombreCliente}</b></h4>
            { usuarioSolicitante !== "" ? <MostrarStars rating={calificacionCliente || 5} totalStars={5}/> : <></> }
          </div>
        </div>
      </div>

      <div className="service-info">
        <p><span className="icon-blue">🏠</span> <b>Metros Cuadrados:</b> {metrosCuadrados} m²</p>
        <p><span className="icon-blue">🛏</span> <b>Habitaciones:</b> {habitaciones}</p>
        <p><span className="icon-blue">🚿</span> <b>Baños:</b> {banos}</p>
        <p>
          {equiposSuministros ? <span className="icon-green">✔️</span> : <span className="icon-red">❌</span>}
          <b> Tengo Equipos y Suministros:</b> {equiposSuministros ? "Sí" : "No"}
        </p>

        <p><span className="icon-blue">📅</span> <b>Fecha y Horario:</b> {fechaHorario}</p>
        <p><span className="icon-blue">📋</span> <b>Servicios Adicionales:</b> {serviciosAdicionales}</p>
        <p><span className="icon-green">💲</span> <b>Presupuesto del Cliente:</b> BOB {presupuestoCliente}</p>
      </div>


      {calificacionCliente === "" && clicked === false  && <div className="footer-servicio">
        <InputStars onChange={handleRatingChange} />
        {/* <p>Tu calificación: {userRating}</p> */}
        <button className="btn-custom btn-calificar" onClick={handleClickCalificarCliente}>Calificar</button>
      </div>}
    </div>
  );
};

export default ClienteServicioFinalizado;
