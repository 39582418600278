import React, { useEffect, useState } from 'react';
import Pagination from './Pagination';
import Servicio from './Servicio';
import LandingPage from '../LandingPage';
import { useAuth } from '../../context/AuthContext';
import { WEB_SERVICES_URL } from '../../constants/Url';

const Servicios = (props) => {
  const [serviciosData, setServiciosData] = useState([]);
  const { verifyToken, isLoggedIn, logout, selectedServices, setSelectedServices } = useAuth();
  const {setLastVisitedRoute} = props;
  const [token, setToken] = useState(null);
  const [timer, setTimer] = useState(0);
  setLastVisitedRoute('/');

  useEffect(() => {
    setTimeout(() =>{
      setTimer([]);
    }, 5000)
  },[timer]);


  useEffect(() => {
      const params = new URLSearchParams(window.location.search);
      setToken(params.get('token') ?? localStorage.getItem('id_token'));
      // console.log("servicios is logged in: "+isLoggedIn());
      // console.log("servicios token: "+token);
      // console.log("localstorage id_token: "+localStorage.getItem('id_token'));
      
      if (!isLoggedIn()) {
          handleLoginWithToken(token);
      } else if (isLoggedIn()) {
          handleLoginWithToken(token);
      }
  }, []); // Agrega isLoggedIn a las dependencias del useEffect

  useEffect(() => {
    if(token == null) return;
    fetch(WEB_SERVICES_URL+`/users/serviciosprestados`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error al obtener datos del usuario");
        }
        return response.json();
      })
      .then((data) => {
        setSelectedServices(data || []); // Inicializar con servicios actuales
      })
      .catch((error) => console.error("Error fetching user data:", error));
  }, [token]);


  const handleLoginWithToken = async (token) => {
      try {
          if(token === undefined) {
              console.log(token);
              // logout();
              return;
          }
          // console.log("ok "+token);
          await verifyToken(token);
      } catch (error) {
          console.error('Error al iniciar sesión:', error);
      }
  };

  useEffect(() => {
    if(token === null) return;
    // Hacer la solicitud HTTP a tu servicio REST
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          try {
            const response = await fetch(WEB_SERVICES_URL + `/limpieza?lat=${lat}&lng=${lng}&services=${JSON.stringify(selectedServices)}`);
            const data = await response.json();
            setServiciosData(data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }, [selectedServices, timer]);

  return (
    isLoggedIn() ? <>
    <div className="grid mb-40">
      {serviciosData.length === 0 ? <p className="text-center">No hay solicitudes de servicios en este momento</p> : <p></p>}
      {serviciosData.map((servicio, index) => (
        <Servicio
          key={index}
          id={servicio._id}
          tipoServicio={servicio.tipoServicio}
          metrosCuadrados={servicio.metrosCuadrados}
          habitaciones={servicio.habitaciones}
          banos={servicio.banos}
          equiposSuministros={servicio.equiposSuministros}
          fechaHorario={new Date(servicio.fechaHorario).toLocaleString('es-ES', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          })}
          presupuestoCliente={servicio.presupuestoCliente}
          serviciosAdicionales={servicio.serviciosAdicionales}
          distanciaEstimada={servicio.distanciaEstimada}
        />
      ))}      
    </div>
    <Pagination />
    </> : 
    <>
    <LandingPage />
    </>
  );
};

export default Servicios;
