import { useState, useEffect } from "react";
import { WEB_SERVICES_URL } from './../constants/Url';
import { useAuth } from './../context/AuthContext';
import defaultImg from './../defaultavatar.png';
import { Link } from 'react-router-dom';

export default function Perfil(props) {
  const [user, setUser] = useState(null);
  const { verifyToken, isLoggedIn, mode } = useAuth();
  const {setLastVisitedRoute} = props;
  setLastVisitedRoute('/perfil');
  let token = null;
  
  
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        token = params.get('token') ?? localStorage.getItem('id_token');
        // console.log("servicios is logged in: "+isLoggedIn());
        // console.log("servicios token: "+token);
        // console.log("localstorage id_token: "+localStorage.getItem('id_token'));
        
        if (!isLoggedIn()) {
            handleLoginWithToken(token);
        } else if (isLoggedIn()) {
            handleLoginWithToken(token);
        }
    }, []); // Agrega isLoggedIn a las dependencias del useEffect
  
    const handleLoginWithToken = async (token) => {
        try {
            if(token === undefined) {
                console.log(token);
                ///logout();
                return;
            }
            // console.log("ok "+token);
            await verifyToken(token);
        } catch (error) {
            console.error('Error al iniciar sesión:', error);
        }
    };

  useEffect(() => {
    fetch(WEB_SERVICES_URL+`/users/data/${token}`)
      .then((response) => response.json())
      .then((data) => setUser(data))
      .catch((error) => console.error("Error fetching user data:", error));
  }, [token]);

  if (!user) {
    return <div className="text-center p-20">Cargando...</div>;
  }

  return (
      <div className="perfil-container">
        <div className="perfil-header">
          <img src={user.img == '' ? defaultImg : user.img} alt="Avatar" className="perfil-avatar" />
          <h2 className="perfil-name">{user.givenName} {user.familyName}</h2>
          <p className="perfil-info">{user.email}</p>
          <p className="perfil-info">{user.phone}</p>
          <p className="perfil-info">{user.address}</p>
          <br/>
          {mode == 'proveedor' && <p className="perfil-info">Crédito: BOB {user.saldo.toFixed(2) || 0}<br/><br/></p>}
          {isLoggedIn() && mode === 'proveedor' && <Link className="btn-payment" to="/payment"><i className="fa fa-dollar"></i> Recarga Crédito</Link>}
        </div>
      </div>

  );
}
