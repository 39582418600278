
import {TextInput,NumberInput,RadioInput,CheckboxInput,DateInput} from './Inputs';
import {ServiciosDisponibles} from './../constants/ServiciosDisponibles';
import CardLandingPage from '../main/CardLandingPage';
import DatePicker from './DatePicker';

export function SelectServicio(props) {
  const { handleSelect } = props;

  const handleClick = (value) => {
    handleSelect(value);
  };

  return (
    <>
      <label htmlFor="tipoServicio">Seleccione un servicio:</label>
      <div className="opciones-servicios">
        {ServiciosDisponibles.map((servicio) => {
          return <CardLandingPage key={servicio.name} src={servicio.src} title={servicio.title} onClick={() => handleClick(servicio.name)}/>;
        })}
      </div>
    </>
  );
}

export function LimpiezaRegular (props) {
    const {formData, handleChange} = props;
    return(
      <>
        <label htmlFor="tipoLimpieza">Tipo de Limpieza:</label>
        <select name="tipoLimpieza" id="tipoLimpieza" onChange={handleChange}>
          <option value="">-- Seleccione un servicio --</option>
          <option value="limpiezaRegular">Limpieza regular</option>
          <option value="limpiezaProfunda">Limpieza profunda</option>
          <option value="limpiezaUnica">Limpieza de una sola vez</option>
        </select>
        <NumberInput name="metrosCuadrados" label="Metros cuadrados" value={formData.metrosCuadrados} onChange={handleChange} />
        <NumberInput name="habitaciones" label="Habitaciones" value={formData.habitaciones} onChange={handleChange} />
        <NumberInput name="banos" label="Baños" value={formData.banos} onChange={handleChange} />
        <CheckboxInput name="equiposSuministros" label="Tengo Equipos y suministros" value={formData.equiposSuministros} onChange={handleChange} />
        
        <DatePicker  name="fechaHorario" label="Fecha y horario preferido" type="datetime-local" value={formData.fechaHorario} onChange={handleChange}/>
        
        <NumberInput name="presupuestoCliente" label="Presupuesto del cliente" value={formData.presupuestoCliente} onChange={handleChange} />
        <TextInput name="serviciosAdicionales" label="Servicios adicionales (Opcional)" value={formData.serviciosAdicionales} onChange={handleChange} />
    </>
    );
}

