import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function DatePickerComponent({ name, label, type, value, onChange }) {
  // Obtiene la fecha y hora actual y le agrega x minutos
  const minDate = new Date(); // Obtenemos la fecha actual
  minDate.setMinutes(minDate.getMinutes() + 30); // Suma los minutos

  // Función para filtrar horas menores a 30 minutos del futuro
  const filterTime = (time) => {
    const selectedTime = new Date(time);

    // Si la hora seleccionada es menor a 30 minutos del futuro, la bloqueamos
    return selectedTime.getTime() >= minDate.getTime();
  };

  return (
    <div className="flex flex-col gap-2">
      <label className="text-sm font-medium text-gray-700">{label}</label>
      <DatePicker
        selected={value ? new Date(value) : null}
        onChange={(date) => onChange({ target: { name, value: date?.toISOString() || "" } })}
        showTimeSelect
        dateFormat="Pp"
        minDate={minDate} // Bloquea las fechas y horas menores a 30 minutos en el futuro
        filterTime={filterTime} // Aplica el filtro de tiempo
        className="border rounded-md p-2 w-full"
      />
    </div>
  );
}
