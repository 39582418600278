import React, {useState, useEffect} from "react";
import { ServiciosDisponibles } from '../../../constants/ServiciosDisponibles';
import { WEB_SERVICES_URL } from '../../../constants/Url';
import { useNavigate, useLocation } from "react-router-dom";
import MostrarStars from '../../../forms/MostrarStars';
import CancelarServicio from "../../../shared/CancelarServicio";
import Spinner from "../../../shared/Spinner";

const ClienteServicioEnProceso = ({
  id,
  tipoServicio,
  metrosCuadrados,
  habitaciones,
  banos,
  equiposSuministros,
  fechaHorario,
  presupuestoCliente,
  serviciosAdicionales,
  latitud,
  longitud,
  usuarioAsignado,
  nombreUsuarioAsignado,
  calificacionUsuarioAsignado
}) => {
  // Encontrar el servicio de limpieza
  const imagen_servicio = ServiciosDisponibles.find(
    (servicio) => servicio.name === tipoServicio
  );
  const [timer, setTimer] = useState(0);
  const [updatedData, setUpdatedData] = useState({
    id,
    tipoServicio,
    metrosCuadrados,
    habitaciones,
    banos,
    equiposSuministros,
    fechaHorario,
    presupuestoCliente,
    serviciosAdicionales,
    latitud,
    longitud,
    usuarioAsignado,
    nombreUsuarioAsignado,
    calificacionUsuarioAsignado
  });

  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    setTimeout(() =>{
      setTimer([]);
    }, 5000)
  },[timer]);

  useEffect(() => {
    const currentPath = location.pathname;

    // Verificar si la ruta actual es '/pendientes', y si es así, salir de la función
    if (currentPath === '/pendientes') return;

    // Hacer la solicitud HTTP a tu servicio REST
    const fetchData = async () => {
      try {
        // Usar 'await' para esperar la respuesta de 'fetch'
        const response = await fetch(WEB_SERVICES_URL + `/limpieza/` + id);
        
        // Verificar si la respuesta es exitosa
        if (!response.ok) {
          throw new Error('Error en la respuesta del servidor');
        }
  
        // Convertir la respuesta a formato JSON
        const data = await response.json();
        
        // Mostrar los datos en consola
        setUpdatedData(data);
        if(updatedData.usuarioAsignado != ''){
          setTimeout(() => {
            navigate("/pendientes");
          },[5000])
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData(); // Llamar a la función asincrónica
  
  }, [timer]); // Dependencia de 'timer' para ejecutar cada vez que cambie
  

  // Obtener la ruta de la imagen
  const imagen_select = imagen_servicio ? imagen_servicio.src : "https://placehold.co/600x400";

  return (
    <div className="w3-third w3-container w3-margin-bottom card-servicios">
      <div className="flex align-items-center">
        <picture className="image-container">
          <img
            src={imagen_select}
            alt={tipoServicio}
            className="responsive-image w3-hover-opacity"
          />
        </picture>
        <div className="flex flex-col align-items-center p-10">
          <h2 className="service-title">
            <span className="icon-blue">📦</span> {tipoServicio}
          </h2>
          <div className="p-10">
            <h4 className=""><b>{nombreUsuarioAsignado}</b></h4>
            { usuarioAsignado !== "" ? <MostrarStars rating={calificacionUsuarioAsignado || 5} totalStars={5}/> : <></> }
          </div>
        </div>
      </div>

      <div className="w3-container w3-white parent-dropdown-click">
          <CancelarServicio message={"¿Estás seguro de cancelar la solicitud?"} id={id} mode_={'cliente'}/>
          <br/>
          <div className="footer-servicio">
            <p className="text-center">{usuarioAsignado === "" && updatedData.usuarioAsignado == '' ? "Esperando que alguien tome tu solicitud..." : "Solicitud Aceptada"}</p>
            {usuarioAsignado === "" && updatedData.usuarioAsignado == '' ? <Spinner className="center-block" status="loading"/> : <Spinner className="center-block" status="ok"/>}
          </div>
            <br/>

                  <div className="service-info">
                    <p><span className="icon-blue">🏠</span> <b>Metros Cuadrados:</b> {metrosCuadrados} m²</p>
                    <p><span className="icon-blue">🛏</span> <b>Habitaciones:</b> {habitaciones}</p>
                    <p><span className="icon-blue">🚿</span> <b>Baños:</b> {banos}</p>
                    <p>
                      {equiposSuministros ? <span className="icon-green">✔️</span> : <span className="icon-red">❌</span>}
                      <b> Tengo Equipos y Suministros:</b> {equiposSuministros ? "Sí" : "No"}
                    </p>

                    <p><span className="icon-blue">📅</span> <b>Fecha y Horario:</b> {fechaHorario}</p>
                    <p><span className="icon-blue">📋</span> <b>Servicios Adicionales:</b> {serviciosAdicionales}</p>
                    <p><span className="icon-green">💲</span> <b>Presupuesto del Cliente:</b> BOB {presupuestoCliente}</p>
                  </div>

                  {latitud && longitud && (
                    <p className="map-link">
                      <a
                        href={`https://www.google.com/maps?q=${latitud},${longitud}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="service-button"
                      >
                        📍 Ver ubicación en el mapa
                      </a>
                    </p>
              )}

      </div>
    </div>
  );
};

export default ClienteServicioEnProceso;
