import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { WEB_SERVICES_URL } from '../../constants/Url';
import { ServiciosDisponibles } from '../../constants/ServiciosDisponibles';
import { useAlertContext } from "../../context/alertContext";

const Servicio = ({
  id,
  tipoServicio,
  metrosCuadrados,
  habitaciones,
  banos,
  equiposSuministros,
  fechaHorario,
  presupuestoCliente,
  serviciosAdicionales,
  distanciaEstimada,
}) => {
  const { onOpen } = useAlertContext();
  // Encontrar el servicio de limpieza
  const imagen_servicio = ServiciosDisponibles.find(
    (servicio) => servicio.name === tipoServicio
  );
  const navigate = useNavigate();

  // Obtener la ruta de la imagen
  const imagen_select = imagen_servicio ? imagen_servicio.src : "https://placehold.co/600x400";

  const [clicked, setClicked] = useState(false);

  const handleClickAceptarServicio = () => {
    const token = localStorage.getItem('id_token');

    axios.put(WEB_SERVICES_URL + `/limpieza/asignar/${id}`, { token: token })
    .then(response => {
      if (response.status === 204) {
        // Ejecutar acción cuando el código de estado es 200 o 201
        setClicked(true);
        onOpen("success", "Servicio aceptado.");
        navigate("/pendientes")
      } else {
        setClicked(true);
        onOpen("error", response.error);
        // Si la respuesta tiene otro código de estado, manejarlo aquí
        console.error('Respuesta inesperada:', response);
      }
    })
    .catch(error => {
      // Manejar errores de la solicitud
      setClicked(true);
      const msg = error.response.data.error;
      onOpen("error", msg);
      if(msg == "Saldo insuficiente."){
        navigate('/perfil');
      }
      // Aquí podrías manejar el error de la solicitud como un error de red, etc.
    });
    };

  return (
    !clicked && <div className="w3-third w3-container w3-margin-bottom card-servicios">
      <div className="flex align-items-center">
      <picture className="image-container">
        <img
          src={imagen_select}
          alt={tipoServicio}
          className="responsive-image w3-hover-opacity"
        />
      </picture>
      <div className="flex align-items-center">
          <h2 className="service-title p-10">
            <span className="icon-blue">📦</span> {tipoServicio}
          </h2>
          </div>
      </div>

      <div className="service-info">
        <p><span className="icon-blue">🏠</span> <span>Metros Cuadrados:</span> {metrosCuadrados} m²</p>
        <p><span className="icon-blue">🛏</span> <span>Habitaciones:</span> {habitaciones}</p>
        <p><span className="icon-blue">🚿</span> <span>Baños:</span> {banos}</p>
        <p>
          {equiposSuministros ? <span className="icon-green">✔️</span> : <span className="icon-red">❌</span>}
          <span>Tengo Equipos y Suministros:</span> {equiposSuministros ? "Sí" : "No"}
        </p>
        <p><span className="icon-blue">📅</span> <span>Fecha y Horario:</span> {fechaHorario}</p>
        <p><span className="icon-green">💲</span> <span>Presupuesto del Cliente:</span> BOB {presupuestoCliente}</p>
        <p><span className="icon-blue">📋</span> <span>Servicios Adicionales:</span> {serviciosAdicionales}</p>
        <p><span className="icon-blue">📍</span> <span>Distancia Estimada:</span> {distanciaEstimada > 1 ? `${distanciaEstimada.toFixed(2)} KM` : `${(distanciaEstimada * 1000).toFixed(2)} Metros`}</p>
      </div>

      <div className="footer-servicio">
        <button className="btn btn-custom" onClick={handleClickAceptarServicio}>Aceptar servicio</button>
      </div>
    </div>
  );
};

export default Servicio;
