import { FileText, Loader, Home, User, Settings } from "lucide-react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from './../context/AuthContext';

export default function BottomNav(props) {
    const { isLoggedIn, mode } = useAuth();
    const location = useLocation();
    function closeSidebar() {
        props.setIsSidebarOpen(false);
      }


  return (
    <div className="flex-jsb bottom-nav fixed bottom-0 left-0 w-full bg-white border-t border-gray-300 shadow-md flex justify-around py-3">
      <span className={location.pathname === '/' ? 'active-menu' : ''} ><NavItem icon={Home} to="/"  label={"Inicio"} active={location.pathname === "/"} closeSidebar={closeSidebar} /></span>

      {isLoggedIn() && mode === "cliente" && (
        <span className={location.pathname === '/solicitados' ? 'active-menu' : ''} >
            <NavItem
                icon={Loader}
                label="Solicitados"
                to="/solicitados"
                active={location.pathname === "/solicitados"}
                closeSidebar={closeSidebar}
            />
        </span>
      )}

      {isLoggedIn() && (
        <span className={location.pathname === '/pendientes' ? 'active-menu' : ''} >
            <NavItem
            icon={FileText}
            label={mode === "cliente" ? "En curso" : "Pendientes"}
            to="/pendientes"
            active={location.pathname === "/pendientes"}
            closeSidebar={closeSidebar}
            />
        </span>
      )}

      <span className={location.pathname === '/perfil' ? 'active-menu' : ''} ><NavItem icon={User} to="/perfil" label={"Perfil"} active={location.pathname === "/perfil"} closeSidebar={closeSidebar} /></span>
      <span className={location.pathname === '/configuracion' ? 'active-menu' : ''} ><NavItem icon={Settings} to="/configuracion"  label={"Ajustes"} active={location.pathname === "/configuracion"} closeSidebar={closeSidebar} /></span>
    </div>
  );
}

function NavItem({ icon: Icon, label, to, active, closeSidebar }) {
  return (
    <Link to={to} onClick={closeSidebar} className="relative group">
      <Icon className={`w-6 h-6 ${active ? "text-blue-600" : "text-gray-600"}`} />
      <span className="absolute bottom-12 left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition-opacity">
        {label}
      </span>
    </Link>
  );
}
