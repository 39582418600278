import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility"; // Corrige los íconos de Leaflet
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";

// Componente para actualizar dinámicamente el centro del mapa
const RecenterMap = ({ position }) => {
  const map = useMap();

  useEffect(() => {
    map.setView(position);
  }, [position, map]);

  return null;
};

const MapComponent = ({ onCoordinatesChange }) => {
  const [position, setPosition] = useState({ lat: -17.7833, lng: -63.1821 }); // Coordenadas iniciales de Santa Cruz

  // Obtener ubicación del cliente usando la geolocalización
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setPosition({ lat: latitude, lng: longitude });
          onCoordinatesChange({ lat: latitude, lng: longitude })
        },
        () => {
          console.log("No se pudo obtener la ubicación.");
        },
        {
          enableHighAccuracy: true, // Usa GPS para mayor precisión
          timeout: 10000, // Espera hasta 10s antes de fallar
          maximumAge: 0, // No usa caché, siempre pide la ubicación actual
        }
      );
    }
  }, []);

  const LocationMarker = () => {
    useMapEvents({
      click(e) {
        const newCoords = e.latlng;
        setPosition(newCoords);
        onCoordinatesChange(newCoords); // Actualiza las coordenadas en el padre
      },
    });

    return (
      <Marker position={position}>
        <Popup>
          Coordenadas seleccionadas:
          <br />
          {`Lat: ${position.lat.toFixed(5)}, Lng: ${position.lng.toFixed(5)}`}
        </Popup>
      </Marker>
    );
  };

  return (
    <MapContainer center={position} zoom={15} style={{ height: "400px", width: "100%" }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <RecenterMap position={position} />
      <LocationMarker />
    </MapContainer>
  );
};

export default MapComponent;
