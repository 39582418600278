import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from './../context/AuthContext';
import LandingPage from '../main/LandingPage';
import Mapa from '../main/servicios/Mapa';
import { useAlertContext } from "../context/alertContext";
import { WEB_SERVICES_URL } from '../constants/Url';
import {SelectServicio, LimpiezaRegular} from '../forms/FormulariosEspecificos';
import BotonVolver from './BotonVolver';

const SolicitudLimpieza = (props) => {
  const navigate = useNavigate();
  const { onOpen } = useAlertContext();
  const { isLoggedIn } = useAuth();
  const token = localStorage.getItem('id_token');
  const [formType, setFormType] = useState("");
  const [formData, setFormData] = useState({
    tipoServicio: "",
    tipoLimpieza: "",
    metrosCuadrados: "",
    habitaciones: "",
    banos: "",
    equiposSuministros: false,
    fechaHorario: "",
    presupuestoCliente: "",
    serviciosAdicionales: "",
    latitud: "",
    longitud: "",
    token: token
  });

  const requiredFields = [
    "tipoServicio",
    "tipoLimpieza",
    "metrosCuadrados",
    "habitaciones",
    "banos",
    "fechaHorario",
    "presupuestoCliente",
    "latitud",
    "longitud"
  ];
  

  useEffect(() => {
    if (formType) {
      setFormData((prevData) => ({
        ...prevData,
        tipoServicio: formType,
      }));
    }
  }, [formType]); // Run the effect whenever `formType` changes.
  
  const handleCoordinatesChange = (newCoords) => {
    setFormData((prevData) => ({
      ...prevData,
      latitud: newCoords.lat, // Redondear a 6 decimales
      longitud: newCoords.lng,
    }));

    // console.log(formData);
  };

  const {setLastVisitedRoute} = props;
    setLastVisitedRoute('/');

  const handleSelect = (value) => {
    setFormType(value);
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    if(name === 'tipoServicio') setFormType(value);
    if (type === "checkbox") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post(WEB_SERVICES_URL+"/limpieza", formData)
      .then((response) => {
        onOpen("success", "Se guardó la solicitud correctamente.");
        document.getElementById('myForm').reset();
        setFormData({});
        setLastVisitedRoute('/solicitados');
        navigate("/solicitados");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const isFormValid = requiredFields.every(field => formData[field]?.toString().trim() !== "");

  return (
    isLoggedIn() ? (
      <div>
        {formType !== "" && <BotonVolver formType={formType} setFormType={setFormType}/>}
        <form id="myForm" onSubmit={handleSubmit}>
          <h3 className="title-solicitud">Solicitud de Servicio</h3>
          {formType === "" && <SelectServicio handleSelect={handleSelect}/>}
          <br/>
          <div className="form-inputs">
            {formType === 'limpieza' && <><LimpiezaRegular formData={formData} handleChange={handleChange}/> <Mapa onCoordinatesChange={handleCoordinatesChange}/> </>}
            {formType !== "" && 
                <input 
                type="submit"
                value="Enviar solicitud"
                disabled={!isFormValid} 
                className={!isFormValid ? 'btn-centered disabled' : 'btn-centered' }
              />
            }
          </div>
        </form>
      </div>
    ) : <LandingPage />
  );
};

export default SolicitudLimpieza;
