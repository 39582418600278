import { StarIcon } from '@chakra-ui/icons';
import { Box } from '@chakra-ui/react';

const MostrarStars = ({ rating, totalStars }) => {
  const stars = [];

  for (let i = 0; i < totalStars; i++) {
    stars.push(
      <StarIcon
        key={i}
        color={i < rating ? 'yellow.500' : 'gray.200'}
        boxSize={6}
      />
    );
  }

  return <Box>{stars}</Box>;
};

export default MostrarStars;
