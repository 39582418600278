import React, { useState, useEffect } from 'react';
import { WEB_SERVICES_URL } from '../constants/Url';

const Payment = (props) => {
    const [amount, setAmount] = useState(20);
    const [error, setError] = useState(false);

    const {setLastVisitedRoute} = props;
    setLastVisitedRoute('/payment');
    
    useEffect(() => {
        const checkoutButton = document.getElementById('checkout-button');
        if (checkoutButton) {
            checkoutButton.addEventListener('click', function(event) {
                const amountInput = document.getElementById('amount');
                if (amountInput) {
                    const amountValue = amountInput.value;
                    if(amountValue < 20){
                        setError(true);
                    }
                    const hiddenAmountInput = document.getElementById('hiddenAmount');
                    if (hiddenAmountInput) {
                        hiddenAmountInput.value = amountValue;
                    }
                }

                const hiddenToken = document.getElementById('hiddenToken');
                if(hiddenToken){
                    hiddenToken.value = localStorage.getItem('id_token');
                }
            });
        }
    }, []);

    const handleChange = (e) => {
        setAmount(e.target.value);
        const amountInput = document.getElementById('amount');
        if (amountInput) {
            const amountValue = amountInput.value;
            if(amountValue < 20){
                setError(true);
            }else{
                setError(false);
            }
        }
    }

    return (
        <section>
            <div className="product">
                <img className='logoCentered' src="apple-touch-icon.png" alt="Unclickservicios Logo" />
                <div className="description m-20">
                    <h3>Recarga crédito - UnClickServicios</h3>
                    <h5>Ingrese el monto</h5>
                    <input
                        type="number"
                        id="amount"
                        name="amount"
                        min="20"
                        value={amount}
                        onChange={(e) => handleChange(e)}
                    />

                    { error && <div className="error">Valor mínimo: 20</div>}
                    
                    <form action={WEB_SERVICES_URL + "/payment/create-checkout-session"} method="POST">
                        <input type="hidden" id="hiddenAmount" name="hiddenAmount" value="" />
                        <input type="hidden" id="hiddenToken" name="hiddenToken" value="" />
                        <button className={"btn-payment"} type="submit" id="checkout-button" disabled={!!error}>Checkout</button>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default Payment;