import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { WEB_SERVICES_URL } from '../constants/Url';
import { useAlertContext } from '../context/alertContext';

function CancelarServicio({message, id, mode_}) {
  const [mostrarOpciones, setMostrarOpciones] = useState(false);
  const [mostrarModal, setMostrarModal] = useState(false);
  const { onOpen } = useAlertContext();
  const navigate = useNavigate();
  const [clicked, setClicked] = useState(false);
  const [motivosSeleccionados, setMotivosSeleccionados] = useState([]);
  const [motivoTexto, setMotivoTexto] = useState('');

  const handleCheckboxChange = (motivo) => {
    const nuevosMotivos = motivosSeleccionados.includes(motivo)
      ? motivosSeleccionados.filter((m) => m !== motivo)
      : [...motivosSeleccionados, motivo];
    setMotivosSeleccionados(nuevosMotivos);
  };

  const handleTextoChange = (event) => {
    setMotivoTexto(event.target.value);
  };

  const motivosPredefinidosCliente = [
    "Encontré otra opción",
    "Tiempo de espera largo",
    "Otro motivo"
  ];

  const motivosPredefinidosProveedor = [
    "Precio bajo",
    "Tomé otro servicio",
    "Otro motivo"
  ];

  const motivosPredefinidos = mode_ == 'cliente' ? motivosPredefinidosCliente : motivosPredefinidosProveedor;
  const ruta = mode_ == 'cliente' ? 'clientecancelar' : 'proveedorcancelar';

  const toggleOpciones = () => {
    setMostrarOpciones(!mostrarOpciones);
  };

  const cancelarSolicitud = () => {
    setMostrarModal(true);
    setMostrarOpciones(false); // Ocultar las opciones después de seleccionar "Cancelar solicitud"
  };

  const cerrarModal = () => {
    setMostrarModal(false);
  };

  const confirmarCancelacion = () => {
    const token = localStorage.getItem('id_token');
    const motivos = [...motivosSeleccionados, motivoTexto].filter(m => m !== "");

    axios.put(WEB_SERVICES_URL + `/limpieza/${ruta}/${id}`, { token: token, motivoCancelacion: motivos })
    .then(response => {
      if (response.status === 204) {
        setClicked(true);
        onOpen("success", "Servicio cancelado.");
        navigate("/")
      } else {
        // Si la respuesta tiene otro código de estado, manejarlo aquí
        console.error('Respuesta inesperada:', response);
      }
    })
    .catch(error => {
      // Manejar errores de la solicitud
      setClicked(true);
      onOpen("error", "Error al cancelar.");
      // Aquí podrías manejar el error de la solicitud como un error de red, etc.
    });
    };

  return (
    <>
      <div className="w3-dropdown-click">
        <button onClick={toggleOpciones} className="w3-button w3-circle">
          {/* Icono de tres puntos */}
          <i className="fa fa-ellipsis-v"></i>
        </button>
        {mostrarOpciones && (
          <div className="w3-dropdown-click">
            <a href="#" className="w3-bar-item w3-button" onClick={cancelarSolicitud}>
              Cancelar solicitud
            </a>
            {/* Puedes agregar más opciones aquí */}
          </div>
        )}
      </div>

      {/* Modal de confirmación */}
      {mostrarModal && (
        <div className={`w3-modal ${mostrarModal && 'modal-show'}`}>
          <div className="w3-modal-content">
            <div className="w3-container">
              <h2>{message}</h2>

              <h3>Motivos de cancelación:</h3>
                {motivosPredefinidos.map((motivo) => (
                    <label key={motivo}>
                    <input
                        type="checkbox"
                        checked={motivosSeleccionados.includes(motivo)}
                        onChange={() => handleCheckboxChange(motivo)}
                    />
                    {motivo}
                    </label>
                ))}

                <label htmlFor="motivo-texto">Otro motivo (especifica):</label>
                <textarea
                    id="motivo-texto"
                    value={motivoTexto}
                    onChange={handleTextoChange}
                    className="w3-input w3-border"
                />
          
              <button className="w3-button w3-green" onClick={confirmarCancelacion}>
                Sí
              </button>
              <button className="w3-button w3-red" onClick={cerrarModal}>
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CancelarServicio;