import { useState, useEffect } from "react";
import ChangeMode from "./leftside/ChangeMode";
import { useAuth } from './../context/AuthContext';
import {ServiciosDisponibles} from './../constants/ServiciosDisponibles';
import { WEB_SERVICES_URL } from './../constants/Url';
import { useAlertContext } from "../context/alertContext";

const Configuracion = (props) => {
  const services = ServiciosDisponibles;
  const { verifyToken, isLoggedIn, mode, selectedServices, setSelectedServices } = useAuth();
  const [token, setToken] = useState(null);
  const { onOpen } = useAlertContext();

  const {setLastVisitedRoute} = props;
  setLastVisitedRoute('/configuracion');

  useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        setToken(params.get('token') ?? localStorage.getItem('id_token'));
        if (!isLoggedIn()) {
            handleLoginWithToken(token);
        } else if (isLoggedIn()) {
            handleLoginWithToken(token);
        }
    }, [token]); // Agrega isLoggedIn a las dependencias del useEffect

  const handleLoginWithToken = async (token) => {
      try {
          if(token === undefined) {
              console.log(token);
              ///logout();
              return;
          }
          // console.log("ok "+token);
          await verifyToken(token);
      } catch (error) {
          console.error('Error al iniciar sesión:', error);
      }
  };

    
  const saveServices = () => {
    fetch(WEB_SERVICES_URL + `/users/serviciosprestados/update`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ selectedServices: selectedServices, token: token })
    })
      .then(() => onOpen("success", "Se guardaron los servicios correctamente."))
      .catch((error) => console.error("Error updating services:", error));
  };

  const handleServiceChange = (service) => {
    setSelectedServices((prev) =>
      prev.includes(service)
        ? prev.filter((s) => s !== service)
        : [...prev, service]
    );
  };

  return (
    <div className="bg-gray-100 rounded-lg shadow-md">
      {isLoggedIn() && (
        <ChangeMode
          isSidebarOpen={props.isSidebarOpen}
          setIsSidebarOpen={props.setIsSidebarOpen}
        />
      )}

      {mode === "proveedor" && (
        <div className="perfil-services p-20">
        <h3 className="perfil-services-title">Selecciona los servicios que deseas ofrecer:</h3>
        <div className="perfil-services-grid">
          {services.map(({ name, title }) => (
            <label key={name} className="perfil-service-item">
              <input
                type="checkbox"
                value={name}
                checked={selectedServices.includes(name)}
                onChange={() => handleServiceChange(name)}
                className="form-checkbox h-5 w-5 text-blue-600"
              />
              <span>{title}</span>
            </label>
          ))}
        </div>
        <button onClick={saveServices} className="btn-centered">
          Guardar servicios
        </button>
      </div>
      
      )}
    </div>
  );
};

export default Configuracion;
